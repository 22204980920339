.auth-contrainer {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #f2f2f8;
}

.auth.contrainer-wrapper {
  min-height: 100vh;
  font-family: "Mplus 1p", sans-serif;
}
.auth.contrainer-wrapper.horizontal {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #f2f2f8;
  max-width: 900px;
  padding: 0 10px;
}

.auth .contrainer-block {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0px 0 0 0;
  width: 100%;
}

.auth .logo {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.auth .logo img {
  vertical-align: middle;
  border-style: none;
  max-width: 500px;
  width: 100%;
  height: 100px;
}

.auth .logo-horizontal {
  display: flex;
  margin: 0 auto 3.25vh;
  width: 240px;
  height: 7.75vh;
}

.auth .logo-horizontal img {
  vertical-align: middle;
  border-style: none;
}

.auth .form-wrapper {
  background: #ffffff;
  box-shadow: 0px 2px 6px #cecfd9;
  border-radius: 6px;
  padding: 5vh 3vh;
  position: relative;
  display: flex;
  flex-direction: column;
}

.auth .form-wrapper.login {
  width: 100%;
  max-width: 500px;
}

.auth .form-wrapper.registration {
  /* max-width: 1200px; */
  margin: 0 auto;
  max-height: 98vh;
}

.auth .form-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5vh;
  min-height: 3vh;
  flex-wrap: wrap;
}
.auth .form-inner {
  max-height: calc(100vh - 125px);
  padding: 0 10px 10px;
  overflow-y: auto;
  overflow-x: hidden;
}
@media (max-height: 500px) {
  .auth .form-inner {
  }
}

.auth .header-info {
  color: #4c4f5e;
  font-size: clamp(1rem, 2.5vh, 1.5rem);
  font-weight: 500;
}
@media (max-height: 500px) {
  .auth .header-info {
    font-size: 2.5vh;
  }
}


.auth .registration-link a {
  color: #1069d5;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
}

.auth .form-container {
  position: relative;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
}

.auth .fields-container {
  position: relative;
}

.auth #form_password_recovery {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.auth .form-bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.auth .form-bottom .btn-link,
.auth .form-bottom a:not(.btn) {
  /* color: #1069D5; */
  font-weight: 500;
}
.auth .form-bottom a:not(.btn) {
  display: flex;
  font-size: 0.85rem;
  padding-top: 10px;
}

.auth .wrapper-button-submit {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  cursor: pointer;
}

.auth .wrapper-button-cancel a,
.auth .wrapper-button-submit a {
  width: 160px;
  max-height: 46px;
  font-size: 16px;
  /* margin: 6px 0; */
  display: flex;
  justify-content: center;
  flex-direction: column;
}
@media (max-width: 414px) {
  .auth .wrapper-button-cancel button,
  .auth .wrapper-button-submit button,
  .auth .wrapper-button-cancel a,
  .auth .wrapper-button-submit a {
    width: 150px;
  }
}
@media (max-height: 500px) {
  .auth .wrapper-button-cancel a,
  .auth .wrapper-button-submit a {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 36px;
  }
  .auth .wrapper-button-cancel button,
  .auth .wrapper-button-submit button {
    max-height: 36px;
    height: 36px;
  }
  .auth .form-bottom a:not(.btn) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    padding: 0;
  }
}

.auth .wrapper-button-cancel button,
.auth .wrapper-button-submit button {
  position: relative;
  width: 160px;
  max-height: 46px;
  font-size: 16px;
  /* margin: 6px 0; */
}

.auth .custom-form-outline {
  height: 74px;
}

.auth .was-validated .form-control:valid,
.auth .was-validated .form-control:invalid {
  margin-bottom: 0;
}

.auth .footer {
  margin-top: 17px;
  margin-bottom: 16px;
  position: fixed;
  bottom: 0;
  right: 49px;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: #9398ab;
}
.auth .faqButton {
  text-align: right;
}
.auth .material-icons {
  position: absolute;
  top: 10px;
}

.auth .material-icons:first-child {
  left: 0;
}

.auth .material-icons:last-child {
  right: 0;
}

.auth .data-list dd {
  margin-left: 26px;
}

.auth .term-condition-detail {
  font-size: 0.9rem;
  overflow: auto;
  height: 55vh;
  margin-bottom: 15px;
  background-color: #f2f2f8;
  padding: 10px;
}

.auth .data-list .form-group {
  height: 50px;
}

.auth .data-list .form-label {
  font-weight: bold;
  margin-bottom: 0;
}

.auth .data-list .form-control {
  border: 0;
  padding: 0 4px;
  margin-left: 20px;
}

.auth .signing-in-loading {
  height: 46px;
  min-width: 160px;
  padding: 4px;
  color: #666;
}

.password-input {
    position: relative;
}
.custom-form-outline.empty-value input[type="password"] + .password-toggle svg{
  opacity: 0.3;
}
.custom-form-outline input[type="password"] + .password-toggle svg{
  opacity: 1.0;
}

.password-toggle {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
}

.password-registration {
  position: absolute;
  top: 44%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.password_message {
  font-size: 0.88rem;
}

.auth-verify-input-password:invalid,
.auth-verify-input-password:valid {
  background-position: right calc(.375em + 2.25rem) center !important;
}