.list-subheader {
    padding: 0
}

.filter-menu {
    padding: 0
}

.filter-search {
    background-color: #e8e8ec;
    outline: none;
    transition: all 0.2s;
}

.sort-label {
    cursor: pointer;
}

.sort-label:hover {
    color: #007BFF;
}

.sort-label.active {
    color: #007BFF;
}

.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.clear-button {
    cursor: pointer;
    text-decoration: underline;
    margin-right: 8px;
}

.header-inner-container {

}

.header-inner-container:hover {
    background-color: #f2f2f8;
    cursor: pointer;
}

.is-filtered {
    color: #007BFF;
    font-weight: bold;
}