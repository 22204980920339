
.site-wrapper {
  font-family: 'Noto Sans JP', sans-serif;
  text-align: center;
  background-color: #F2F2F8;
  width: 100%;
  height: 100vh;
  color: #002B69;
  overflow-y:auto;
}

.main {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  min-height: 660px;
  line-height: 1.5;
  padding: 15px;
}

.logo {
  position: fixed;
  top: 15px;
  left: 15px;
  width: 180px;
}

@media (max-width: 540px) {
  .logo {
    width: 180px;
  }
}

.logo img {
  width: 100%;
  height: auto;
}


@media (max-width: 540px) {
  .p1 {
    font-size: 24px;
  }
}

.p2 {
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  margin-bottom:0;
}

.maintenance_img {
  width: 320px;
  margin: 20px auto 50px;
}


@media (max-width: 540px) {
  .maintenance_img {
    width: 240px;
  }
}

.maintenance_img img {
  width: 100%;
  height: auto;
}

.guidance {
  margin: 0 auto;
  font-size: 16px;
}

.guidance_head {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.guidance_head::before,
.guidance_head::after {
  content: "";
  flex: 1;
  border-top: 1px solid #002b69;
}

.guidance_head::before {
  margin-right: 5px;
}

.guidance_head::after {
  margin-left: 5px;
}

.guidance_inner {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  font-size: 18px;
  padding: 0 5px;
  line-height: 2;
}
.guidance_inner dt {
  font-weight:bold;
}

.guidance_inner dd {
  padding-left: 1em;
}
