.delivery_header {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.delivery_header_action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.text_header {
  width: 110px;
}

.icon_actions {
  cursor: pointer;
}
