.loading {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    user-select: none;
}

.loading.full-screen {
    height: 100vh;
}

.form-outline .form-label {
    white-space: nowrap;
}

.invalid-feedback {
    /* height: 0; */
}

.test {
    margin: 0;
}