/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/
.active_chip {
    color: white !important;
    box-shadow: 0 1px 4px #00000a59;
}

.container {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 8px;
}