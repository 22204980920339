ul,
li {
    list-style-type: none;
}

.draggable-list__item {
    padding: 10px 0;
    border-top: 2px solid transparent;
}

.draggable-list__item *:not(.file-name, .file-remove) {
    pointer-events: none;
}

.draggable-list__item.dragstart {
    opacity: 0.5;
}

.draggable-list__item.dragover {
    border-top-color: #007bff;
}

.draggable-list__item svg{
  opacity: 0;
  transition-duration: .3s;
}

.draggable-list__item:hover svg{
  opacity: 0.8;
}
