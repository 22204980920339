.document-process-item {
    padding: 0 16px 16px;
    background-color: #131314;
    width: 400px;
}

.document-process-item:hover {
    background-color: #242424;
}

.document-process-title {
    width: 85%;
    font-size: 14px !important;
}
