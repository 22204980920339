/* .pdf-fragment {
    width: 100%;
    height: 100%;
    padding: 20px;
    padding-bottom: 40px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: #888;
    overflow: auto
} */

.pdf-fragment:not(.pdf-full-preview) {
  width: 100%;
  height: 100%;
  min-width: 280px;
  max-width: 280px;
  min-height: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  /* border-left: 1px solid #e2e2e8; */
  overflow-x: hidden;
}
@media (max-width: 991px) {
  .pdf-fragment:not(.pdf-full-preview) {
    min-height: inherit;
  }
}

.full-right-body-with-file-list,
.pdf-fragment.pdf-full-preview {
  width: 100%;
  height: calc(100vh - 60px);
  position: relative;
  display: flex;
  justify-content: space-between;
  /* position: absolute; */
}
.documentinfo-container .pdf-fragment.pdf-full-preview,
.full-right-body-with-file-list .pdf-fragment.pdf-full-preview {
  height: calc(100% - 60px);
}

.form-container-with-file-list,
.pdf-fragment .pdf-preview-section {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  /* position: absolute; */
}
@media only screen and (max-width: 991px) {
  .form-container-with-file-list,
  .pdf-fragment .pdf-preview-section  {
    height: inherit;
  }
}

.pdf-fragment:not(.pdf-full-preview) .pdf-preview-section {
  min-height: 380px;
  max-height: 380px;
}
@media (max-width: 991px) {
  .pdf-fragment:not(.pdf-full-preview) .pdf-preview-section {
    max-height: 100%;
    min-height: 0;
    aspect-ratio: 230 / 332;
  }
}

.pdf-fragment.drag-over .pdf-preview-section,
.pdf-fragment .pdf-preview-section.no-file {
  position: unset;
}

.pdf-fragment.pdf-full-preview .pdf-preview-section {
  background-color: #474e63;
  box-shadow: inset 0 0 15px rgba(52, 59, 69, 0.8);
}

.pdf-fragment .pdf-info-section {
  background-color: #fff;
}

.pdf-fragment.pdf-full-preview .pdf-info-section {
  min-width: 300px;
  max-width: 500px;
  position: relative;
  width: 30%;
}

@media only screen and (max-width: 767px) {
  .active-mobile .pdf-fragment.pdf-full-preview .pdf-info-section {
    display: block !important;
  }
  .pdf-fragment.pdf-full-preview .pdf-info-section .file-item {
    display: flex;
  }
  .pdf-fragment.pdf-full-preview .pdf-info-section .file-item.active {
    color: #00d0ff;
  }
  .pdf-fragment.pdf-full-preview .pdf-info-section {
    background-color: #545d75;
    border-top: 1px solid #2c2e3f;
    color: #d5d5e6;
    position: fixed;
    width: 100%;
    max-height: 160px;
    top: auto;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .pdf-fragment.pdf-full-preview .pdf-info-section ul {
    margin-bottom: 0;
  }
  .full-right-body-with-file-list, .pdf-fragment.pdf-full-preview {
    height: calc(100vh - 200px);
  }
  .d-sm-none .file-preview-zone {
    /* height: auto !important;
    margin-top: -80px; */
    height: 0 !important;
  }
}

.pdf-fragment:not(.pdf-full-preview) .pdf-info-section {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;
}
@media only screen and (max-width: 991px) {
  .pdf-fragment:not(.pdf-full-preview) .pdf-info-section {
    height: inherit;
  }
}

.pdf-fragment:not(.pdf-full-preview) .pdf-info-section.slide {
  position: unset;
}

.pdf-fragment .react-pdf__Document {
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pdf-fragment .nofile {
  display: none;
}

.pdf-fragment
  .react-pdf__Document
  .react-pdf__message.react-pdf__message--no-data {
  padding: 100px 40px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pdf-fragment .pdf-no-data {
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 120px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  text-align: center;
  padding: 8px;
  box-shadow: 4px 4px 4px #444;
}

.pdf-fragment .file-upload-panel {
  pointer-events: all;
}

.template-new .file-upload-panel .drop-zone-panel,
.template-new .file-upload-panel .drop-zone-panel input[type="file"],
.pdf-fragment .file-upload-panel .drop-zone-panel,
.pdf-fragment .file-upload-panel .drop-zone-panel input[type="file"],
.pdf-fragment.drag-over .file-upload-panel label.info,
.pdf-fragment .file-upload-panel .upload-file-list {
  display: none;
}

.template-new.drag-over .file-upload-panel .drop-zone-panel,
.template-new .file-upload-panel.no-file .drop-zone-panel,
.pdf-fragment.drag-over .file-upload-panel .drop-zone-panel,
.pdf-fragment .no-file .file-upload-panel .drop-zone-panel {
  top: 0;
  left: 0;
  z-index: 20;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: #545d75;
  box-shadow: inset 0 0 4px rgb(0 0 0 / 16%);
}

.layout .layout-right .right-body .file-panel .file-drop-zone,
.layout .layout-right .right-body .file-panel .file-preview-zone {
  padding: 20px 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
@media only screen and (max-width: 991px) {
  .layout .layout-right .right-body .file-panel .file-preview-zone {
    padding: 0;
    height: inherit !important;
  }
}

.layout .layout-right .right-body .file-panel .file-drop-zone,
.layout .layout-right .right-body .file-panel .file-preview-zone,
.pdf-fragment .no-file .file-upload-panel .drop-zone-panel {
  background: #545d75;
  box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
}
@media only screen and (max-width: 991px) {
  .layout .layout-right .right-body .file-panel .file-drop-zone,
  .layout .layout-right .right-body .file-panel .file-preview-zone,
  .pdf-fragment .no-file .file-upload-panel .drop-zone-panel {
    box-shadow: none;
  }
}

.template-new .file-upload-panel .dropbox-area,
.pdf-fragment .file-upload-panel .dropbox-area {
  margin: 0;
  cursor: pointer;
  height: 297px;
  width: 210px;
  background-size: 100px;
  border: 1px dashed #7a85a3;
  background-color: #656f8a;
  background-repeat: no-repeat;
  background-position: center;
}

/* .pdf-fragment .file-upload-panel .dropbox-area .drop-area-inner { */

.template-new .dropbox-area .drop-area-inner,
.pdf-fragment .dropbox-area .drop-area-inner {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.template-new .file-upload-panel .dropbox-area .drop-area-inner svg,
.pdf-fragment .file-upload-panel .dropbox-area .drop-area-inner svg {
  width: 150px;
  min-height: 60px;
  color: #cecfd9;
}

.template-new .no-file.file-upload-panel label.info {
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  margin: 10px;
  color: #d5d5e6;
}

.template-new .no-file.file-upload-panel .dropbox-area:hover,
.pdf-fragment .no-file .file-upload-panel .dropbox-area:hover {
  border-color: #40a9ff;
}

.template-new.drag-over .file-upload-panel .dropbox-area,
.pdf-fragment.drag-over .file-upload-panel .dropbox-area {
  width: 100%;
  height: 100%;
  border: none;
  padding: 20px;
  background: #545d75;
}

.template-new.drag-over .file-upload-panel .dropbox-area .drop-area-inner,
.pdf-fragment.drag-over .file-upload-panel .dropbox-area .drop-area-inner {
  border: 2px dashed #40a9ff;
  background-color: #656f8a;
}

.pdf-fragment .pdf-page-panel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.pdf-fragment.pdf-full-preview .pdf-page-panel.active {
  margin-top: 20px;
}

.pdf-fragment.pdf-full-preview .pdf-page-panel:first-child,
.pdf-fragment .pdf-page-panel:first-child {
  margin-top: 0;
}

.pdf-fragment .pdf-preview-panel {
  width: 100%;
  height: 100%;
  padding: 20px 20px 40px 20px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  overflow: auto;
}
@media (max-width: 991px) {
  .pdf-fragment .pdf-preview-panel {
    position: relative;
    height: auto;
    /* overflow: hidden; */
  }
}

.pdf-fragment.pdf-full-preview .pdf-preview-panel {
  padding: 20px 20px 85px 20px;
}

.pdf-fragment:not(.pdf-full-preview) .pdf-preview-panel {
  justify-content: center;
  background: #a4acc1;
  box-shadow: inset 0 0 10px rgba(45, 50, 60, 0.4);
}

.template-new .file-upload-panel,
.pdf-fragment .pdf-controller-panel {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.template-new .file-upload-panel {
  pointer-events: all;
}

.pdf-fragment .mini-view-action-panel {
  position: absolute;
  height: 100%;
  width: 100%;
}

.pdf-fragment .mini-view-action-panel .btn-icon {
  position: absolute;
  /* right: 6px;
    top: 6px; */
  top: 0;
  right: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  pointer-events: all;
  color: #535963;
}

.pdf-fragment .mini-view-action-panel .btn-icon svg {
  /* position: absolute;
  top: 0;
  right: 0; */
  font-size: 27px;
  transition: all 0.2s;
}

/* .pdf-fragment .mini-view-action-panel .btn-icon.full-preview {
    right: 10px;
} */

.pdf-fragment .mini-view-action-panel .btn-icon.full-preview {
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 0;
}

.pdf-fragment .mini-view-action-panel .btn-icon.full-preview .btn-fullscreen {
  position: absolute;
  top: 15px;
  right: 18px;
  outline: none;
  transition: all 0.3s;
}
.pdf-fragment .mini-view-action-panel .btn-icon.full-preview .ripple::after,
.pdf-fragment .mini-view-action-panel .btn-icon.full-preview .ripple::before {
  content: "";
  position: absolute;
  left: -25%;
  top: -25%;
  border: 1px solid #f27200;
  width: 150%;
  height: 150%;
  border-radius: 50%;
  opacity: 1;
  animation: 1s circleanime linear infinite;
}

.pdf-fragment .mini-view-action-panel .btn-icon.full-preview.ripple::before {
  animation-delay: 0.5s;
}

@keyframes circleanime {
  0% {
    transform: scale(0.68);
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
.pdf-fragment
  .mini-view-action-panel
  .btn-icon.full-preview.ripple
  span::after {
  content: "";
  position: absolute;
  top: 38%;
  left: 45%;
  border: 14px solid transparent;
  border-top-width: 10px;
  border-bottom-width: 10px;
  border-left-color: #fff;
}

.pdf-fragment .mini-view-action-panel .btn-icon.show-pdf-info {
  z-index: 1;
  width: 35px;
  height: 35px;
  right: 25px;
  top: 15px;
}

.pdf-fragment .mini-view-action-panel .btn-icon.new-window {
  top: 40px;
  right: 10px;
}

.pdf-fragment .mini-view-action-panel .btn-icon:hover svg {
  /* font-size: 35px; */
  transform: scale(1.4);
}

.pdf-fragment .page-navigator {
  position: absolute;
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  border-radius: 4px;
  padding: 2px 10px;
  pointer-events: all;
}

.pdf-fragment .pdf-properties {
  position: absolute;
  font-size: 14px;
  overflow: auto;
  padding: 15px 15px 25px;
  height: 100%;
  width: 100%;
  left: 100%;
  top: 0;
  transition-duration: 0.4s;
  transition-timing-function: ease;
  background: #f2f2f8;
  z-index: 1;
}

.pdf-fragment .pdf-properties.active {
  left: 0;
  width: 100%;
}

.pdf-fragment .pdf-properties .pdf-info-header .btn-icon.pdf-info-close {
  width: 35px;
  height: 35px;
  color: #062b60;
}

.layout
  .layout-right
  .right-body
  .pdf-fragment.pdf-full-preview
  .pdf-info-section
  .pdf-properties,
.layout
  .layout-right
  .right-body
  .pdf-full-preview-wrapper
  .pdf-fragment
  .pdf-info-section {
  background-color: #545d75;
  border-left: solid 1px #2c2e3f;
  color: #d5d5e6;
}

.layout .layout-right .right-body .upload.file-panel .file-upload-list,
.layout
  .layout-right
  .right-body
  .pdf-fragment.pdf-full-preview
  .pdf-info-section.file-panel
  .file-upload-list {
  border-top: solid 1px #2c2e3f;
  background-color: #545d75;
  color: #d5d5e6;
}

.layout
  .layout-right
  .right-body
  .pdf-full-preview
  .file-panel
  .file-upload-list
  .file-item.active,
.layout
  .layout-right
  .right-body
  .upload.file-panel
  .file-upload-list
  .file-item.active {
  color: #00d0ff;
}

.layout
  .layout-right
  .right-body
  .pdf-full-preview
  .file-panel
  .file-upload-list
  .file-item:not(.active):hover,
.layout
  .layout-right
  .right-body
  .upload.file-panel
  .file-upload-list
  .file-item:not(.active):hover {
  background: #4c536a;
}

.pdf-fragment .pdf-properties .pdf-info-header {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  padding-bottom: 6px;
}

.pdf-fragment .pdf-properties .pdf-info-header span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.pdf-fragment .pdf-properties .pdf-info-header .btn-icon.pdf-info-close {
  width: 35px;
  height: 35px;
  color: #474e63;
}

.pdf-fragment .pdf-properties .pdf-info-header .btn-icon.pdf-info-close svg {
  font-size: 25px;
}

.pdf-fragment.pdf-full-preview
  .pdf-properties
  .pdf-info-header
  .btn-icon.pdf-info-close {
  color: #fff;
}

.pdf-fragment .pdf-properties .pdf-info-header .btn-icon.pdf-info-close:hover {
  background-color: #e2e2e8;
}

.pdf-fragment.pdf-full-preview
  .pdf-properties
  .pdf-info-header
  .btn-icon.pdf-info-close:hover {
  background-color: #474e63;
}

.pdf-fragment .pdf-properties ul {
  padding-left: 15px;
  margin: 0;
}

.pdf-fragment .pdf-properties li {
  list-style: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.pdf-fragment .pdf-properties .label {
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.pdf-fragment .pdf-properties .value {
  font-size: 0.81rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #545d75;
}

.layout
  .layout-right
  .right-body
  .pdf-fragment.pdf-full-preview
  .pdf-info-section
  .pdf-properties
  .value {
  color: #b8c6e8;
}

.pdf-fragment .pdf-properties .separator {
  width: 100%;
  border-bottom: 1px solid #dadae4;
  margin: 15px 0 10px;
}

.pdf-fragment .pdf-properties .pdf-info-header.separator {
  margin-top: 0;
}

.pdf-fragment .pdf-properties li {
  padding-top: 10px;
}

.pdf-fragment .pdf-properties .value {
  padding-top: 0px;
}

.layout
  .layout-right
  .right-body
  .pdf-fragment.pdf-full-preview
  .pdf-info-section
  .pdf-properties
  .separator,
.layout
  .layout-right
  .right-body
  .pdf-full-preview-wrapper
  .pdf-fragment
  .separator {
  border-bottom: 1px solid #2c2e3f;
}

.pdf-fragment .button-file-upload {
  pointer-events: all;
  height: 40px;
  width: 90px;
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  right: 24px;
  top: 70px;
}

.pdf-fragment .button-file-upload label {
  height: 100%;
  width: 100%;
  margin: 0;
  cursor: pointer;
  border-radius: 50px;
  background-color: #007bff;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 0.85rem;
  font-weight: 500;
  transition: all 0.2s;
  box-shadow: 0 1px 5px rgba(44, 50, 59, 0.85);
}

.pdf-fragment .button-file-upload label:hover {
  background-color: #0275f0;
  box-shadow: 0 3px 10px rgb(44, 50, 59);
}

.pdf-fragment .pdf-file-info {
  position: absolute;
  left: 10px;
  top: 10px;
  pointer-events: all;
}

.pdf-fragment .react-pdf__Page__canvas {
  cursor: move;
}

.pdf-full-preview-wrapper {
  position: fixed;
  top: 135px;
  left: 100%;
  height: 16px;
  width: 0;
  z-index: 20;
  transition-delay: 0.1s;
  transition-duration: 0.4s;
  transition-timing-function: ease;
  pointer-events: none;
  opacity: 0;
}

.pdf-full-preview-wrapper.active {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: all;
  opacity: 1;
}

.pdf-full-preview-wrapper.active {
  background-color: #545d75;
}

.pdf-full-preview-wrapper.active .header-wrapper {
  background-color: #545d75;
  z-index: 200;
  border-bottom: solid 1px #2c2e3f !important;
}

.layout
  .layout-right
  .right-body
  .file-panel
  .file-preview-zone
  .pdf-full-preview-wrapper.active
  .pdf-custom-page.active
  canvas {
  box-shadow: 0 0 18px rgb(44, 50, 59);
}

.layout .document-detail .pdf-full-preview-wrapper.active .btn-return {
  color: #d5d5e6;
}

.layout .pdf-full-preview-wrapper .btn-action,
.layout .pdf-full-preview-wrapper .btn-return {
  color: #d5d5e6;
  border: none;
  width: 50px;
  height: 50px;
  transition: all 0.2s;
  background: #545d75;
}

.layout .pdf-full-preview-wrapper .btn-return:hover {
  background: #474e63;
}

.layout .pdf-full-preview-wrapper .btn-return > svg {
  font-size: 32px;
}
