.base_tenant_card {
  margin: 25px 0px;
}

.base_tenant_card:nth-of-type(2) {
  margin-top: 15px;
}

.button_action {
  display: flex;
  justify-content: flex-end;
}

.fa_sync {
  position: absolute;
  right: -47px;
  width: 50px;
  z-index: 9999;
  height: 100%;
  display: flex;
  cursor: pointer;
  justify-content: center;
  background: #e9ecef;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  border-left: 1px solid;
  -webkit-text-fill-color: #000 !important;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.form_text {
  /* overflow: hidden;
  text-overflow: ellipsis; */

}