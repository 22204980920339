.confirm-guest.container-wrapper {
    display: flex;
    justify-content: center;
    font-family: 'Mplus 1p', sans-serif;
}

.confirm-guest.container-wrapper .horizontal {
    display: flex;
    justify-content: center;
    width: 100vh;
}

.confirm-guest .container-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px 0 0 0;
    width: 100%;
}

.confirm-guest .form-wrapper {
    background: #FFFFFF;
    border-radius: 6px;
    padding: 28px;
    position: relative;
    display: flex;
    flex-direction: column;
}

.confirm-guest .form-wrapper form {
    display: flex;
    flex-direction: column;
}

.confirm-guest .form-container {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.confirm-guest .form-bottom {
    display: flex;
    justify-content: space-between;
}

.confirm-guest .form-bottom {
    display: flex;
    justify-content: space-between;
}

.confirm-guest .form-bottom .wrapper-button-cancel .btn {
    margin-right: 8px;
}

.confirm-guest .form-bottom .wrapper-button-submit .btn {
    margin-left: 8px;
}

.confirm-guest .footer {
    margin-top: 17px;
    margin-bottom: 16px;
    position: fixed;
    bottom: 0;
    right: 49px;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #9398ab;
}

.confirm-guest .container-info {
    /*display: flex;*/
    /*flex-direction: column;*/
    max-width: 600px;
}

.confirm-guest .title {
    font-size: 28px;
    font-weight: bolder;
}

.confirm-guest .subtitle {
    font-weight: bold;
    margin: 24px 0;
}

.confirm-guest .guest-info {
    display: flex;
    flex-direction: row;
    word-break: break-all;
    align-items: center;
    margin-bottom: 25px;
}
@media (max-width: 767px) {
  .confirm-guest .item-label {
    width: auto;
  }
  .confirm-guest .guest-info {
    flex-direction: column;
    align-items: flex-start;
    padding-left:1em;
    padding-bottom: 10px;
    border-bottom:1px dashed #e2e2e8;
  }
}

.tenant-select .MuiBox-root {
    padding: 0;
}
.tenant-select .MuiBox-root .MuiInput-root::before,
.header-right .MuiBox-root .MuiInput-root::before {
  display: none;
}

.tenant-select .MuiBox-root .MuiInput-root > div:focus,
.header-right .MuiBox-root .MuiInput-root > div:focus {
  background: none;
}
.confirm-guest .item-label {
  position:relative;
    width: 140px;
    flex-shrink: 0;
    margin-bottom: 0;
}
@media (max-width: 540px) {
  .confirm-guest .item-label {
    margin-bottom: 0 !important;
    text-indent:-1em;
  }
}


.confirm-guest .item-label::after {
  position:absolute;
  content:':';
  right: .5em;
}
@media (max-width: 540px) {
  .confirm-guest .item-label::after {
    display: none;
  }
}


.confirm-guest .guest-info .tenant-switch .MuiSelect-select{
  padding: 0;
}

.confirm-guest .container-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 36px auto 0 auto;
}

.confirm-guest .button {
    min-height:  30px;
    box-shadow :  0px 1px 6px rgba(0, 0, 0, 0.26);
    border-radius :  6px;
    padding :  8px;
    font-size :  16px;
    text-align :  center;
    font-weight: normal;
    text-transform: none;
    width: 120px;
    margin: 0 15px;
}

.confirm-guest .button-approve {
    color :  #FFFFFF;
    background-color :  #1069D5;
}
.confirm-guest .button-approve:hover {
    color :  #FFFFFF;
    background-color :  #1069D5;
}

.confirm-guest .button-reject {
    background-color :  #e2e6e9;
    color :  #1069D5;
}

.confirm-guest .button-reject:hover {
    background-color :  #e2e6e9;
    color :  #1069D5;
}