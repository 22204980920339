/* ResizableTable.css */

/* Style for the resizer */
.resizable th {
    position: relative;
    padding-right: 10px; /* Space for the resizer */
}

/* Table styles (customize as needed) */
.resizable-container {
    /* overflow: auto;
    width: calc(100vw - 255px);
    height: calc(100vh - 165px); */
}

.resizable thead tr {
    /* top: 0!important; */
}

.resizable {
    border-collapse: collapse;
}

.resizable th,
.resizable td {
    /* padding: 8px; */
    /*border: 1px solid #ccc;*/
}