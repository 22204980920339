.drive_base_dialog {
}
.drive_base_dialog_title {
}
.drive_base_dialog_content * {
  font-size: 14px !important;
}
.drive_base_dialog_actions {
}

.drive_upload_dialog_upload {
  width: 100%;
  display: flex !important;
  justify-content: space-between !important;
  border: 1px solid rgb(151, 151, 151) !important;
}

.drive_upload_dialog_inputfile{
  white-space: pre-line;
}