/* @import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap'); */

#pwa-toast {
    visibility: hidden;
    position: fixed;
    right: 0;
    top: 0;
    margin: 16px;
    padding: 12px;
    border: 1px solid #8885;
    border-radius: 4px;
    z-index: 10;
    text-align: left;
    box-shadow: 3px 4px 5px 0 #8885;
    background-color: #fff;
  }
  #pwa-toast .message {
    margin-bottom: 8px;
  }
  #pwa-toast button {
    /* border: 1px solid #8885;
    outline: none;
    margin-right: 5px;
    border-radius: 2px;
    padding: 3px 10px; */
  }

  .refresh-btn{
    display: inline-flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      position: relative;
      box-sizing: border-box;
      -webkit-tap-highlight-color: transparent;
      outline: 0px;
      border: 0px;
      margin: 0px 16px 0px 0px;
      cursor: pointer;
      user-select: none;
      vertical-align: middle;
      appearance: none;
      text-decoration: none;
      font-family: Roboto, Helvetica, Arial, sans-serif;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.75;
      letter-spacing: 0.02857em;
      text-transform: uppercase;
      min-width: 64px;
      padding: 3px 9px;
      border-radius: 4px;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      color: rgb(255, 255, 255);
      background-color: rgb(25, 118, 210);
      box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  }

  .close-btn{
    display: inline-flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      position: relative;
      box-sizing: border-box;
      -webkit-tap-highlight-color: transparent;
      background-color: transparent;
      outline: 0px;
      margin: 0px;
      cursor: pointer;
      user-select: none;
      vertical-align: middle;
      appearance: none;
      text-decoration: none;
      font-family: Roboto, Helvetica, Arial, sans-serif;
      font-weight: 500;
      font-size: 0.8125rem;
      line-height: 1.75;
      letter-spacing: 0.02857em;
      text-transform: uppercase;
      min-width: 64px;
      padding: 3px 9px;
      border-radius: 4px;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      border: 1px solid rgba(25, 118, 210, 0.5);
      color: rgb(25, 118, 210);
  }
  #pwa-toast.show {
    visibility: visible;
  }
  button#pwa-refresh {
    display: none;
  }
  button#pwa-close {
    display: none;
  }
  #pwa-toast.show.refresh button#pwa-refresh {
    display: inline-block;
  }
  #pwa-toast.show.refresh button#pwa-close {
    display: inline-block;
  }

.p1 {
    font-size: 36px;
    font-weight: bold;
    font-family: "Quicksand", sans-serif !important;
    text-align: center;
}


@media (max-width: 540px) {
  .p1 {
    font-size: 24px;
  }
}

*:not(.material-icons-outlined):not(.material-icons,.p1) {
    font-family: 'Noto Sans JP', 'Roboto', 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

body {
    margin: 0;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #062b60;
}

.secondaryText {
    color: #535963;
}

.secondaryTextW {
    color: #e2e2e8;
}

.smallText {
    font-size: 0.81rem;
}

.xsmallText {
    font-size: 0.75rem;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a,
tr,
li,
button {
    transition: all .2s ease-in-out;
}

a:hover,
button:hover {
    text-decoration: none;
}

a.as-link:hover {
    color: #fff;
}
.main.contrainer-wrapper {
  overflow: clip;
}

.main-body-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
}

.full-body-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 99999;
    background-color: white;
}


.form-wrapper .form-row input,
.form-wrapper .form-row select {
    height: 40px;
    margin-bottom: 0;
}
.form-wrapper .form-row input::placeholder,
.form-wrapper .form-row select::placeholder {
  color: #9398ab;
}

.form-wrapper .form-row input#create_date_from,
.form-wrapper .form-row input#create_date_to,
.form-wrapper .form-row input#amount_from,
.form-wrapper .form-row input#amount_to,
.form-wrapper .form-row input#deal_date_from,
.form-wrapper .form-row input#deal_date_to,
.form-wrapper .form-row input#contract_date_from,
.form-wrapper .form-row input#contract_date_to,
.form-wrapper .form-row input#expire_date_from,
.form-wrapper .form-row input#expire_date_to,
.form-wrapper .form-row input#complete_signing_datetime_from,
.form-wrapper .form-row input#complete_signing_datetime_to {
  width: 93%;
}
.search-section .input-group>.custom-file,
.search-section .input-group>.custom-select,
.search-section .input-group>.form-control,
.search-section .input-group>.form-control-plaintext {
  flex: inherit;
  width: 100%;
}

.auth .form-group {
    height: 86px;
}

.btn-icon, .btn-icon-bell, .btn-icon-bell-active {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    border-radius: 50%;
    cursor: pointer;
    border: 0;
    background-color: transparent;
}

.receive-mail-viewer .icon-bell, .icon-bell-active {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    border-radius: 50%;
    border: 0;
    background-color: transparent;
}
.icon-bell-active {
    color: #007bff;
}


/* .btn-icon:hover {
    background-color: #e2e2e8;
} */

.custom-switch {
    height: 46px;
    display: flex;
    align-items: center;
}

.form-label {
    font-size: 0.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    margin-bottom: 0px;
    color: #535963;
}

.custom-collapse {
    overflow: hidden;
    transition-duration: 0.6s;
    transition-timing-function: ease;
}

.custom-collapse:not(.show) {
    height: 0px !important;
}

.form-control-no-validate {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-link {
    color: #007bff;
    text-decoration: none;
    border: none;
    background-color: transparent;
    /* text-overflow: ellipsis; */
    /* overflow: hidden; */
    /* white-space: nowrap; */
    width: 100%;
}

.btn-link:hover {
    color: #0056b3;
    text-decoration: none;
}

.hide {
    display: none;
}

.standard-prepend-append {
    background-color: #e2e2e8;
    color: #495057;
    border-color: #ced4da;
}

input[type=number] {
    text-align: right;
}

.align-right {
    text-align: right;
}

.data-loading {
    animation-name: loading;
    animation-duration: 1.6s;
    animation-iteration-count: infinite;
}

@keyframes loading {
    0% {
        filter: brightness(1);
    }

    50% {
        filter: brightness(0.9);
    }
}
.modal-content {
  box-shadow: 0 0 20px rgba(0,0,0,0.35);
}
.modal-full {
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    height: 100%;
    margin: 0;
}

.modal-full .modal-content {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
}

.modal-working-logs {
    padding: 4px 0;
    display: flex;
    align-items: center;
}

.modal-working-logs .status-icon {
    padding: 0 4px;
}

.modal-working-logs .message-label {
    padding: 0 4px;
}

.modal-working-logs .status-icon .success {
    color: #00ab00;
}

.modal-working-logs .status-icon .failure {
    color: #e40000;
}

.modal-dialog.zero-padding .modal-body {
    padding: 0;
}

.modal-dialog .modal-body .modal-body-action {
    transition-duration: 0.4s;
    background-color: green;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 3;
}

.modal-dialog .modal-body .body-action-container {
    padding-right: 30px;
    position: relative;
}

.modal-dialog .modal-body .body-action-bar {
    height: 0px;
    transition-duration: 0.4s;
    overflow: hidden;
}

.modal-dialog .modal-body .body-action-bar-toggle {
    position: absolute;
    top: 0;
    right: -6px;
    height: 30px;
    width: 30px;
    z-index: 2;
    transition-duration: 0.4s;
    transform: translateY(-30px);
}

.modal-dialog .modal-body .body-action-bar-toggle .toggle-icon {
    height: 30px;
    font-size: 30px;
    border-radius: 50%;
}

.modal-dialog .modal-body .body-action-bar-toggle .toggle-icon:hover {
    background-color: #eeeeee;
}

.modal-dialog .modal-footer > a:hover > span {
  color: white;
}

.modal-dialog .modal-footer button + button {
  font-size: 0.875rem;
  padding: 6px 16px;
  margin-left: 8px;
}

.modal-header,
.modal-footer {
  border: none;
}
.modal-dialog form .form-group {
  height: auto;
  margin: 0;
}

.counter-party-dialog.modal-dialog form .form-group .status {
  margin-left: 10px;
}
.modal-dialog .modal-body {
  padding-top: 0;
  padding-bottom: 0;
}
.form-control {
  border-color:#0095F8;
}
.guest-select .user-selection-container.form-group{
    height: 100% !important;
}

.modal-select-guest{
    width: 1000px !important;
    max-width: none !important;
}

.modal-dialog form .form-label {
    margin-bottom: 0;
}

.modal-dialog form .form-control {
    min-height: 46px;
}

.modal-dialog form .form-control-file .custom-file {
    height: 46px;
    min-height: 46px;
}

.modal-dialog form .form-control-file .custom-file-label::after,
.modal-dialog form .form-control-file .custom-file-label,
.modal-dialog form .form-control-file .custom-file-input {
    height: 100%;
    display: flex;
    align-items: center;
}

.css-kp9ftd-MuiIcon-root {
    font-size: 1.7em;
}

.clickable {
    cursor: pointer;
}

.otp_dialog > .MuiDialog-container > .MuiPaper-root {
    padding: 10px 30px 10px 30px;
}

.otp_dialog_actions{
    display: grid;
    justify-content: center;
    align-items: center;
    row-gap: 1rem;
    margin-top: 1rem;
}

.otp_dialog_content {
    display: grid;
    row-gap: 1rem;
}

.otp_dialog_content p{
  margin-left: 1em;
  text-indent: -1em;
  white-space: pre-wrap;
}

.otp_dialog_dl {
  display: flex;
  justify-content: space-between;
}

.otp_dialog_dl_batch {
  width: 35%;
  margin-left: 1em;
}

.otp_dialog_dl_ms-auth{
  width: 164px;
}

.otp_dialog_dl_ms-auth_qr{
  width: 90px;
  margin: 0 auto 15px;
}
.otp_dialog_dl_ms-auth_caption{
  font-size: 0.75rem;
  font-weight: bold;
  background-color: #E8E8E8;
  text-align: center;
  border-radius: 999px;
  padding: 3px 0;
  color: black;
}
.otp_dialog_button_store{
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
}

.otp_dialog_button_store_grid{
    display: grid;
    grid-column: 1 / span 2;
}
.otp_dialog_button_store_guide{
    grid-column: 1 / span 4;
}
.otp_dialog_button_store_qr{
    grid-column: span 2 / span 2;
}
.logo_googplay{
    cursor: pointer;
}
.logo_applestore{
    cursor: pointer;
    height: 75px;
}

.mfa_loading_full{
    min-height: 211px;
}

.guest_settings_layout{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.guest_settings_layout > .checkbox_guest {
   margin-top: 10px;
}
.guest_settings_layout > h2 {
   margin-right: 4rem !important;
}

.mfa_loading_full{
    min-height: 211px;
}

/* Start drive styles */
.root_node {
    align-items: center;
    display: grid;
    grid-template-columns: auto auto 1fr auto;
    height: 34px;
    padding-inline-end: 8px;
    border-radius:999px;
    /* margin-top: 5px; */
}
.bottom-fixed {
    position: fixed;
    background: #fff;
    padding: 10px;
    display: flex;
    align-items: center;
    border-radius: 0px 0px 4px 4px;
    justify-content: flex-end;
    border-top: 1px solid #dde3e5;
}

.app_drive_node {
    height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.treeRoot > li > .root_node:first-of-type  {
    /* background-color: #062b60;
    color: #fff;
    height: 45px; */
}
.app_drive_node>ul {
    padding: 0 5px;
}
.app_drive_node>ul ul{
    padding: 0 0 0 5px;
}
div.app_drive_node{
    padding: 0 5px;
}
.app_drive_node li {
    list-style-type: none;
}
.app_drive_node li>div {
    padding: 0 4px 0 5px !important
}
.app_drive_node ul .tree-node.root_node > div:nth-child(2) svg{
  height:auto;
  font-size:1em;
}
.app_drive_node ul .tree-node.root_node + ul div:nth-child(2) svg{
  font-size:1.25em;
}
.app_drive_node ul .tree-node.root_node + ul div:nth-child(2) svg.fa-folder-open,
.app_drive_node ul .tree-node.root_node + ul div:nth-child(2) svg.trash_icon{
  font-size:0.8em;
}
.app_drive_node .treeRoot > li:nth-child(1) {
  padding-bottom: 10px;
}
.app_drive_node .treeRoot > li:nth-child(2) {
  border-top:2px solid #e8e8ec;
  padding-top: 10px;
}
.app_drive_node .treeRoot > li:nth-child(2) > .tree-node.root_node   {
  display: none;
}
.treeRoot {
    height: 100%;
}
.treeRootSearch,.treeRootSearch ul{
    padding: 0;
    margin-bottom: 5px !important;
}
.popper_tree_search{

    background-color: #fff;
    color: #062b60;
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px 4px 0px 0px;
    box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);
    /* margin: 32px; */
    position: relative;
    overflow-y: auto;
    display: -webkit-box;
    display: -webkit-flex;
    padding: 0px 10px;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.popper_tree_search .treeRoot li ul li {
  margin-left: 5px;
}

.draggingSource {
    opacity: 0.3;
}

.dropTarget {
    background-color: #e8f0fe;
    height: 100%;
}
.treeRoot_drag_area{
    position: absolute;
    top: 229px;
    right: calc(-100vw + 273px);
    width: calc(100vw - 289px);
    height: calc(100vh - 246px);
    /* border-radius: 20px; */
    /* border: 1px dotted #3697E1; */
    /* width: 100vw; */
    z-index: 9999;
    /* background-color: rgba(211,215,226,0.6); */
}
.active_route_drive{
    /* color: #2694ef !important; */
}
.active_route_drive .css-e784if-MuiTypography-root {
  font-weight: bold !important;
}
.treeRoot_drag_area2 {
    position: absolute;
    z-index:1;
    top: 211px;
    right: calc(-100vw + 256px);
    width: calc(100vw - 256px);
    height: calc(100vh - 214px);
    z-index: 9999;
    padding: 0;
}
.treeRoot_drag_area2::after{
    content: '';
    display: flex;
    align-items: center;
    justify-content: center;
    transition:all .2s ease;
    border-radius: 0;
    background-color: rgba(211,215,226,0.6);
    border: 2px dashed #3697E1;
    height: calc(100% - 36px);
    width: calc(100% - 36px);
    top: 18px;
    left: 18px;
    text-align: center;
    position: absolute;
    display: block;
    z-index: 100;
    opacity: 0.6;
}

.mouse-over-popover{
    z-index: 9999999999;
}

.treeRoot_drag_area_3 {
    position: absolute;
    top: 37px;
    width: 100%;
    height: calc(100% - 37px);
    z-index: 9999;
    padding: 0;
}
.treeRoot_drag_area_3::after{
    content: '';
    /* width: 100vw; */
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition:all .2s ease;
    border-radius: 0;
    background-color: rgba(211,215,226,0.6);
    border: 2px dashed #3697E1;
}
.expandIconWrapper {
    align-items: center;
    font-size: 0;
    cursor: pointer;
    display: flex;
    height: 24px;
    justify-content: center;
    width: 15px;
    transition: transform linear 0.1s;
    transform: rotate(0deg);
}

.expandIconWrapper.isOpen {
    transform: rotate(90deg);
}
.expandIconWrapper svg {
  font-size: 0.8rem;
}
/* .expandIconWrapper .css-vubbuv {
  font-size: 50%;
} */

.labelGridItem {
    padding-inline-start: 0px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
}
.checkbox-base-table{
    margin-left: 0 !important;
    padding-left: 0 !important;
    text-align: center !important;
}
.isSelected {
  background-color: #e8e8ec !important;
  font-weight:bold;
}
.MuiTableSortLabel-icon{
    opacity: 1 !important;
}
.node_active{
  /* color: #1967d2; */
  color: #062b60;
}
.labelGridItem > p{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
}

.layout .layout-left .menu-container .menu-box-drive{
    padding: 0;
}

.current_active_folder{
    background-color: rgba(25, 118, 210, 0.2);
}
#resizer2{

    bottom: 0;
    cursor: col-resize;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 5px;
    z-index: 9999;
}
#resizer2:hover{
    background-color: rgba(25, 118, 210, 0.2);
}
#resizer {
    bottom: 0;
    cursor: col-resize;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 5px;
}
.drive_tree_root > li{
    list-style-type: none;
}
#resizer:hover{
    background-color: rgba(25, 118, 210, 0.2);
}


.activenumber_text {
    color: #062b60 !important;
    font-size: 0rem !important;
}

.activenumber_text > span {
    font-size: 0.81rem !important;
}

.deactivenumber_text {
    color: #828688 !important;
    font-size: 0rem !important;
}
.deactivenumber_text > span  {
    font-size: 0.81rem !important;
}
.disabled_pagination{
    pointer-events: none !important;
}
.table_edit_icon_hover{
    /* position: absolute; */
    top: 19px;
    cursor: pointer;
    z-index: 100;
    /* right: 0; */
    margin-left: 1em;
    overflow: visible !important;
}

.highlight_search{
    color: #1976D2;
}
.drive_file_infomation{
    height: calc(100% - 200px) !important;
    bottom: 18px !important;
    right: 0px !important;
    top: unset !important;
    width: 310px !important;
    padding: 20px !important;
    border-top: 1px solid #e2e2e8 !important;
    border-left: 1px solid #e2e2e8 !important;
    border-bottom: 1px solid #e2e2e8 !important;
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12) !important;
}
.drive_file_infomation_header_field p{
    font-size: 0.73rem;
}
.drive_file_infomation_form_field > div{
  margin-top: 5px;
}
.drive_file_infomation_form_field p{
    font-size: 0.85rem;
    color: #535963;
}
.drive_file_infomation_form_field input,.drive_file_infomation_form_field .MuiSelect-select,.drive_file_infomation_form_field textarea{
    font-size: 0.85rem;
}


.drive_form_outlined{
    padding: 0 !important;
}
.drive_file_infomation_field{
    min-height: 60px;

}
.drive_file_infomation_data_wrap{
    display: grid;
    gap: 20px;
}
.drive_file_infomation_header_field{
    display: flex;
    justify-content: space-between;
}
.table_cell_loading {
    background: #fff;
    top: 152px;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content:center;
    z-index: 50;
    align-items: start;
}
.list_table{
    position: relative;
    /* width: unset !important;
    table-layout: fixed; */
}
.virtual_table_wrapper .MuiPaper-root{
    overflow-x: hidden !important;
}
.list_table tr {
  border-color: #e2e2e8 !important;
}
.list_table_th {
    position: sticky;
    z-index: 200 !important;
    border-color: #e2e2e8 !important;
}
.list_table_th.shed {
    z-index: 999 !important;
    filter: drop-shadow(0 1px 3px rgba(black,0.2));
}
.list_table tbody td {
  width: unset !important;
  border-color: #e2e2e8 !important;
}
.table_cell {
    color: #062b60 !important;
    height: 60px !important;
    /* white-space: pre-line !important; */
    padding: 6px;
    position: relative;
    max-width: 0;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.8rem !important;
}
.safari_fix_drawer > .MuiPaper-root{
    overflow-y: unset !important;
}
.right_body_drive{
    overflow-y: hidden;
}
.right_body_drive .table_cell {
    height: 50px !important;
}
.table_header_cell {
    font-size: 0.75rem !important;
    color: #828688 !important;
    font-weight: 500 !important;
    /* padding: 1px 0.75rem 0 !important; */
    padding: 0 !important;
    border-color: #e2e2e8 !important;
}
.table_header_cell:nth-child(1) {
  width:40px;
}
.table_header_cell:nth-child(1) svg{
  font-size:1.2rem;
}
.table_header_cell:nth-child(1) span{
  padding:0 !important;
}
.drive-table .table_header_cell:nth-child(2) {
  width: calc(100% - 30px - 180px - 100px);
}
.delivery-table .table_header_cell:nth-child(2) {
  width: calc(100% - 30px - 180px - 180px);
}
.table_header_cell:nth-child(2) span{
  margin-left: 0 !important;
}
.delivery-table.table_header_cell:nth-last-child(3) {
  width:160px;
}
.drive-table.table_header_cell:nth-last-child(3) {
  width:180px;
}
.delivery-table .table_header_cell:nth-last-child(2) {
  width:120px;
  text-align: center;
}
.drive-table .table_header_cell:nth-last-child(2) {
  width:180px;
  text-align: center;
}
.delivery-table .table_header_cell:nth-last-child(1) {
  width:180px;
  text-align: center;
}
.drive-table .table_header_cell:nth-last-child(1) {
  width:130px;
  text-align: center;
}
.layout .list_table tbody tr:hover {
  background-color:#f2f2f8;
}
.list_table tbody td {
  padding: 0 0;
}
.list_table tbody td:nth-child(1) span {
  padding: 0;
}
.list_table tbody td:nth-child(1) svg {
  font-size: 1.2rem;
}
.list_table tbody td:nth-child(2) div.folder-file_list_file_name {
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 0;
}
.list_table tbody td:nth-child(2) div.folder-file_list_file_name span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.list_table tbody td:nth-last-child(2) {
  text-align: center;
}
.list_table tbody td:nth-last-child(1) {
  text-align: center;
}
.drive_actions_btn {
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    justify-content: center;
    align-items: center;
    column-gap: 15px;
}
.grid-col-3 {
    display:grid !important;
    gap: 3px;
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
}
.align-right input{
    text-align: right !important;
}
.drive_actions_btn svg {
    cursor: pointer;
}
.drive_actions_btn button{
    grid-column: span 3 / span 3;
}
.folder_header_breadcrumb{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 59px;
    padding: 10px 0px;
    border-top: 1px solid rgba(251, 251, 252, 1);

}
.drive_detail_selected_file{
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 5px;
}


.move_dialog_item_icon svg{
    width: 30px;
    height: 30px;
}
.row_header_drive_action{
    margin-left: 0 !important;
}
.search-round-container-drive{
    padding: 7px 0px 13px 10px;
}
.folder_header_search{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.drive_table_header_action{
    margin-left: 0 !important;
    border-bottom: 1px solid rgba(251, 251, 252, 1);
}
.shed_col2.MuiTableCell-stickyHeader{
    top: 111px !important;
}
.shed_col2{
    filter: drop-shadow(0 1px 3px rgba(black,0.2));
}
.pagination_count_page{
    margin-bottom: 0;
}
.search_base{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.icon_pagination{
    color: rgba(0, 0, 0, 0.54);
    padding-top: 2px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.icon_pagination svg {
    border-radius: 50px;
    width: 25px;
    height: 25px;
    cursor: pointer !important;
  /* @apply rounded-3xl w-6 h-6 flex justify-center items-center cursor-pointer select-none border-none bg-transparent; */

}
.icon_pagination svg:hover {
    background: #f2f2f8;
    border-radius: 50px;
}
.icon_pagination svg:nth-child(1) {
    margin-right: 10px;
}
#form-file-upload {
    height: calc(100vh - 204px);
    width: calc(100vw - 256px);
    right: calc(-100vw + 256px);
    text-align: center;
    position: absolute;
    display: block;
    top: 217px;
    z-index: 100;
    opacity: 0.6;
}
.hide_drag_area{
    display: none !important;
    z-index: -1 !important;
}
#input-file-upload {
    display: none;
}
.error-text{
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 3px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    color: #e0463b;
    width: 100%;
}
.full_height{
    max-height: unset !important;
    height: fit-content !important;
    padding-bottom: 11px;
}

#label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    transition:all .2s ease;
    /* border-radius: 20px; */
    /* border-radius: 1rem;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: #f8fafc; */
}

#label-file-upload.drag-active {
    /* background-color: #ffffff; */
    background-color: rgba(211,215,226,0.6);
    border: 2px dashed #3697E1;
}

.upload-button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    font-family: "Oswald", sans-serif;
    background-color: transparent;
}

.upload-button:hover {
    text-decoration-line: underline;
}

#drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    z-index: 100;
    right: 0px;
    bottom: 0px;
    left: 0px;
}
.drive_share_dialog{
    bottom: 0 !important;
    top: unset !important;
    position: absolute !important;
    right: 20px !important;
    left: auto !important;
    border-radius: 6px 6px 0 0;
    margin: 0px !important;
    max-height: calc(100% - 15px) !important;
    margin-top: 10px !important;
    border-top: 1px solid #e2e2e8 !important;
    border-right: 1px solid #e2e2e8 !important;
    border-left: 1px solid #e2e2e8 !important;
    box-shadow: 0px 5px 8px -3px rgba(0,0,0,0.2), 0px 8px 14px 1px rgba(0,0,0,0.14), 0px 3px 20px 2px rgba(0,0,0,0.12) !important;
    overflow:hidden !important;
    max-width:600px;
}
.notistack-MuiContent-warning{
    width: fit-content !important;
    white-space: pre-line;
    max-width: calc(100vw - 150px) !important;
}
.drive_share_dialog_max .drive_share_dialog{
    width: 100% !important;
    margin: 0!important;
    max-height: unset!important;
    margin-top: 0 !important;
    height: 100% !important;
}
.drive_search_mail{
    justify-content: start;
    align-items: center;
    width: 100%;
    background-color: #F3F2F8;
    border-radius: 52px;
    padding: 10px 2px !important;
    display: flex;
    height: 42px;
    cursor: pointer;
}
.drive_search_mail.MuiAutocomplete-option.Mui-focused {
    /* background-color: rgb(122, 182, 243) !important; */
    border-radius: 51px  !important;
}
.drive_search_mail.MuiAutocomplete-option {
    padding-left: 0;
    padding-right: 0;
}
.drive_search_mail_avatar{
    width: 40px;
    height: 40px;
    border-radius: 9999px;
}
.drive_share_dialog_content{
    display: grid;
    margin-top: 3px;
    row-gap: 15px;
    overflow-x: hidden;
    padding: 10px
}
.drive_search_mail_info{
    width: 100%;
}

.drive_search_mail_info h6{
    font-size: 14px;
    line-height: 14px;
}
.drive_search_mail_avatar{
    margin-right: 7px;
}
.drive_search_mail_avatar svg{
    height: 40px;
    width: 40px;
}
.drive_search_mail_wrapper{
    padding: 5px;
}
.drive_selected_files{
    display: grid;
    row-gap: 15px;
}
.drive_selected_files_chip{
    border-radius: 3px;
    padding: 8px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F3F2F8;
}
.drive_selected_files_chip svg{
    width: 0.8em;
}
.drive_selected_files_chip span{
    padding-left: 5px;
}
.drive_selected_files_chip_content{
    display: flex;
    align-items: center;
}
.text_file_chip_full{
    color: #062b60 !important;
    /* height: 60px !important; */
    /* white-space: pre-line !important; */
    /* padding: 6px; */
    position: relative;
    max-width: 334px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.8rem !important;
}
.action_btn_center{
    width: 100%;
    text-align: center;
    justify-content: center;
}

.action_btn_center button{
    width: 100px;
}
.tree-node{
    cursor: pointer;
    z-index: 9999;
}
.tree-node:hover{
    /* background-color: rgba(0, 0, 0, 0.04); */
    background-color: #f2f2f8;
}
.trash_icon{
    padding-left: 4px;
}
.text_file_chip{
    color: #062b60 !important;
    /* height: 60px !important; */
    /* white-space: pre-line !important; */
    /* padding: 6px; */
    position: relative;
    max-width: 134px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.8rem !important;
}
.drive_download_config{
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 50px;
}
.drive_download_config_control p,.drive_share_password p, .drive_share_password span,.drive_share_detail_wrap p{
    font-size: 13px !important;
}
.drive_share_detail_wrap{
    display: grid;
    gap: 10px;
}
.upload_progess_message{
    display: flex;
    gap: 12px;
    align-items: center;
}
.date_input_drive{
    display: flex;
    margin: 10px 0;
    align-items:center;
}
.date_input_drive input{
    width: 125px;
}
.input_drive{
    display:flex;
    margin: 10px 0;
    flex-wrap: wrap;
    align-items:center;
}
.error-border{
    border: 1px solid #e0463b;
}
.drive_share_dialog_action,.drive_share_dialog_title {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}
.drive_share_dialog_title{
    background-color: #F2F6FC;
    padding: 5px 24px;
}

.drive_share_detail_table{
    width: 100%;
}
.drive_share_dialog_action {
  padding-top: 10px;
}

.drive_share_dialog_action_button{
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 8px;
    margin-left: 10px;
}

.drive_share_dialog_icon_title{
    display: flex;
}
.drive_search_result_breab{
    display: flex;
}
.location_parents{
    overflow: hidden;
    width: 100px;
    text-align:start;
    text-overflow: ellipsis;
}
/* end drive styles */


.crumb_wrapper {
  padding-left: 10px
}
.crumb_list {
  font-size: 0.85rem !important;
}
.crumb_list  li {
  font-size: 0.85rem !important;
}
.crumb_list  li a svg{
  margin-right: 5px;
}
.crumb_list  li p svg{
  margin-right: 5px;
}
.crumb_list  li:not(:first-child) p svg{
  display: none;
}
.crumb_list  li a {
  text-decoration: none;
}
.crumb_list  li p{
  font-size: 0.87rem !important;
}
.crumb_item,.icon_button_drive {
  font-size: 0.87rem;
  display:block;
}
.crumb_item_long li a svg {
  margin-right: 5px;
}
.crumb_item{
    display: flex !important;
    align-items:center !important;
    text-decoration: none !important;
}
.cant_preview{
    display: grid;
    justify-content: center;
    gap: 10px;
    color: #FF6600;
}
.drive_folder_fa_icon{
    height: 22px !important;
    /* margin: 0 !important; */
    width: auto !important;
    height: 1.25em!important;
    vertical-align: -0.25em !important;
}
.drive_file_icon{
    font-size: 18px;
    margin:0 4px 0 4px;
}
/* .drive_file_icon{
    font-size: 30px;
    margin-right: 3px;
} */
.drive_file_icon.pdf{
    color: #ed7171;

}
.disabled_input_noborder .MuiInputBase-root{
    padding-left: 0!important;
    padding-right: 0 !important;
}
.disabled_input_noborder fieldset{
    display:none;
}
.drive_file_infomation_form_field p {
    max-width: 268px;
    word-wrap: break-word;
    white-space: pre-line;
}

.disabled_input_noborder .MuiSelect-icon{
    display:none;
}

.col-span-2{
    grid-column: span 2 / span 2;
}

.trash_icon{
    padding-left: 3px;
}
.drive_share_dialog_content {
  overflow-x: hidden;
}

.drive_download_config{
    display: grid;
    grid-template-columns: repeat(2, minmax(60%, 1fr));
    gap: 20px;
}

.upload_progess_message{
    display: flex;
    gap: 12px;
    align-items: center;
}

.close-alert{
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(30%, -30%);
    font-size: 20px !important;
}

.date_input_drive{
    display: flex;
    margin: 10px 0;
    align-items:center;
}
.date_input_drive input{
    width: 150px;
}
.input_drive{
    display:flex;
    margin: 10px 0;
    flex-wrap: wrap;
    align-items:center;
}
.error-border{
    border: 1px solid #e0463b;
}
.input_drive input{
    width: 60px;
    height: 37px;
    margin-right: 5px;
}
.drive_share_dialog_action,.drive_share_dialog_title {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}
.drive_share_dialog_title{
    background-color: #F2F6FC;
    padding: 5px 10px 5px 10px;
}
.drive_share_dialog_title p{
    font-size: 13px;
}
.drive_share_dialog_icon_title svg{
    width :0.8em;
    margin-left: 5px;
}
.drive_share_dialog_action_button{
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 8px;
    margin-left: 10px;
}
.drive_share_dialog_action svg {
  cursor: pointer;
}
.drive_share_detail_table{
    width: 100%;
}
.drive_share_detail_table th{
    width: 130px;
    padding: 10px;
}
.drive_share_detail_table td {
  padding: 10px;
}
.drive_share_dialog_icon_title{
    display: flex;
}
.icon_button_drive{
    font-size: 14px !important;
    color: rgb(25, 118, 210)!important;
    /* display: block !important; */
}
.modal_company_tree{
    height: calc(100vh - 140px);
    overflow: hidden;
}
.material_symbol_icon_drive{
    cursor: pointer;
    display: flex;
    font-size: 24px;
    align-items: center;
    margin-right: 3px;
}
.material-symbols-outlined {
    font-family: 'Material Symbols Outlined' !important;
}

.custom_drive_folder_icon{
    width: 24px !important;
}
.active_route_drive .custom_drive_folder_icon #filebox .path-1{
    fill:#9A005D !important;
}
.treeRoot li:hover svg#filebox .path-2 {
    fill: #d1dded;
}
.treeRoot li:hover svg#filebox .path-1 {
    fill: #95a9c7;
}
.active_route_drive .custom_drive_folder_icon #filebox .path-2{
    fill:#C6248C !important;
}
.root_node:hover .custom_drive_folder_icon svg#filebox .path-2 {
    fill:#C6248C !important;
}
.root_node:hover .custom_drive_folder_icon svg#filebox .path-1{
    fill:#9A005D !important;
}
.active_route_drive .material-symbols-outlined {
  font-variation-settings:
  'FILL' 1;
}
/* end drive styles */
.header-right-pagination{

  display: flex;
  align-items: center;
  font-size: 14px;
  flex-shrink: 0;
}

.header-right-pagination .MuiBox-root .MuiInput-root::before {
    content: unset !important;
}

.header-right-pagination .MuiBox-root .MuiInput-root>div:focus {
    background: none !important;
}

.header-right-pagination .MuiBox-root .MuiInput-root>div,
ul.MuiList-root li {
    font-size: 0.81rem !important;
}
.disabled_click{
    color: grey !important;
}

.disalbed_stamp {
    color: #c9c5c5 !important;
}

.disalbed_stamp svg{
    fill: grey !important;
}
/* end drive styles */

.base_card_box .MuiPaper-root{
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    overflow: hidden;
    background-color: rgb(242, 242, 248);
    padding-left: 0px !important;
}


.field_wrapper {
    /* @apply grid grid-cols-6 gap-6 items-center justify-center ; */
    display: grid;
    grid-template-columns: repeat(8, minmax(0, 1fr));
    gap: 10px;
    justify-content: center;
    align-items: center;
}
.col-span-3{
    grid-column: span 3 / span 3;
}
.col-span-4{
    grid-column: span 4 / span 4;
}
.col-span-2{
    grid-column: span 2 / span 2;
}

.col-span-5{
    grid-column: span 5 / span 5;
}
.col-span-6{
    grid-column: span 6 / span 6;
}
@media (max-width: 991px)  {
  .col-span-2-sm{
      grid-column: span 2 / span 2;
  }
  .col-span-3-sm{
      grid-column: span 3 / span 3;
  }
  .col-span-4-sm{
      grid-column: span 4 / span 4;
  }
  .col-span-5-sm{
      grid-column: span 5 / span 5;
  }
  .col-span-6-sm {
    grid-column: span 6 / span 6;
  }
}
.base_content_grid {
    display: grid;
    gap: 1.5rem;
    position: relative;
    padding: 1rem 2rem;
}
.button-right-shoulder {
  position: absolute;
  top: 1rem;
  right: 2rem;
}
.field_wrapper p {
    grid-column: span 2 / span 2;
}
.css-1ygn15h-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  /* -webkit-text-fill-color:rgb(6, 43, 96) !important; */
}



/* .field_wrapper h6,div {
    color: var(--mui-palette-primary-main);
} */


.grid-template-columns-3{
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
}
.go_back_icon{
    font-size: 25px;
    color: #062b60;
    cursor: pointer;
}

.go_back_wrapper{
    /* padding: 20px 0px; */
    display: flex;
    align-items: center;
    gap: 10px;
}
.activeFlag_active {
  font-size: 12px;
  font-weight: 500;
  padding: 5px 15px 5px;
  border-radius: 999px;
  height: fit-content;
  background-color: rgb(23, 168, 69);
  color: #fff;
  margin-top: 5px;
  display: inline-block;
}
.activeFlag_warning{
    font-size: 12px;
    font-weight: 500;
    padding: 5px 15px 5px;
    border-radius: 999px;
    height: fit-content;
    background-color: rgb(223, 90, 45);
    color: #fff;
    margin-top: 5px;
    display: inline-block;
}
.warning_color {
   color: rgb(223, 90, 45);
}
.activeFlag_error{
  font-size: 12px;
  font-weight: 500;
  padding: 5px 15px 5px;
  border-radius: 999px;
  height: fit-content;
  /* background-color: #dc3545; */
  background-color: rgb(223, 90, 45);
  color: #fff;
  margin-top: 5px;
  display: inline-block;
}

.activeFlag_inactive {
  font-size: 12px;
  font-weight: 500;
  padding: 5px 15px 5px;
  border-radius: 999px;
    height: fit-content;
    background-color: rgba(0, 0, 0, 0.26);
    color: #fff;
    margin-top: 5px;
    display: inline-block;
  }
  .document_active_chip{
    align-items: center;
  }

.valid-form {
    border-color: #28a745;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.valid-form input {
    padding-right: calc(1.5em + 0.75rem) !important;
}
.valid-form fieldset{
    border-color: #28a745 !important;
}

.required-form fieldset{
    border-color: #da6517;
}

.mypage-email-input{
    width: calc(100% - 60px);
}

.required-form.mypage-email-input,
.valid-form.mypage-email-input {
  width: calc(100% - 60px);
}
.valid-form.mypage-email-input input:disabled {
  background-color: rgb(242, 242, 248);
  color: #062b60;
  -webkit-text-fill-color: #062b60;
  padding-right: 0 !important;
}
.valid-form.mypage-email-input input:disabled + fieldset{
  border-color: transparent !important;
}

/* .errors-form {
    border-color: #dc3545;
} */
.errors-form  input {

    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.errors-form .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    left: -13px;
}
.errors-form .Mui-error{
    color: #dc3545 !important;
}

.errors-form  fieldset{
    border-color: #dc3545 !important;
}

.showLoading{
    position: absolute;
    z-index: 9999;
    background: #a4acc1;
    width: 100%;
    height: 100%;
    top: 0;
}

.showLoading .progress{
    display: flex;
    width: 300%;
    margin-top: 20px;
}
.showLoadingProcess .progress{
    display: flex;
    width: 400%;
    margin-top: 20px;
}

.loading .img-container-progress {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pdf-full-preview-wrapper .showLoading{
    background: #474e63 !important;
}
.popper_suggest{
    background: white;
    color: #062b60;
    font-weight: bold;
    padding: 16px 18px;
    border: 2px solid #1976d2;
    font-size: 13px;
    border-radius: 10px;
    z-index: 9999999;
    filter: drop-shadow(0px 2px 4px rgba(0,0,0,0.6));
}
.popper_suggest_fields_ocr {
    background: white;
    color: #062b60;
    font-weight: bold;
    padding: 2px;
    /* overflow: auto;
    max-height: 85px; */
    /* padding: 16px 18px; */
    border: 2px solid #1976d2;
    font-size: 13px;
    border-radius: 8px;
    z-index: 9999;
    filter: drop-shadow(0px 2px 4px rgba(0,0,0,0.6));
    top: .5em !important;
}

.popper_suggest_received_date, .popper_suggest_counter_party_name, .popper_suggest_amount {
    opacity: 0;
    animation: fadeIn ease-in-out 0.4s forwards;
}

.popper_suggest_received_date{
    z-index: 10001;
    animation-delay: 0s;
}

.popper_suggest_counter_party_name{
    z-index: 10000;
    animation-delay: 0.3s;
}

.popper_suggest_amount{
    z-index: 9999;
    animation-delay: 0.6s;
}

@keyframes fadeIn {
    to {
      opacity: 1;
    }
}

.popper_suggest_fields_ocr::before,
.popper_suggest_fields_ocr::after {
  position: absolute;
  content:'';
  display: block;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
}
.popper_suggest_fields_ocr::before {
  left: -8px;
  border-width: 7px 7px 7px 0;
  border-color: transparent #1976d2 transparent transparent;
}
.popper_suggest_fields_ocr::after {
  left: -5px;
  border-width: 5px 5px 5px 0;
  border-color: transparent #ffffff transparent transparent;
}
.popper_suggest_fields_ocr .popper_arrow {
  display:none;
}
.popper_suggest_fields_ocr .business_info_data_wrapper .MuiList-root{
    padding: 0 !important;
}
.popper_suggest_fields_ocr .business_info_data_wrapper{
    /* max-height: 67px; */
    max-height: 136px;
    overflow: auto;
}
.popper_suggest_fields_ocr .business_info_data_wrapper .menu_list_ocr {
  padding: 2px 14px;
}
.popper_suggest_fields_ocr .business_info_data_wrapper .menu_list_ocr:hover {
  background-color: #f2f2f8;
}
.popper_suggest_fields_ocr .business_info_data_wrapper span{
  font-size:0.75rem;
}
.popper_suggest_fields_ocr.popper_suggest_invoice_number .business_info_data_wrapper{
  max-height: 100%;
}
.popper_suggest_invoice_number{
    z-index: 99999999;
    max-width:200px;

}
.popper_suggest_invoice_number .business_info_data_wrapper{
    max-height: none!important;
    padding: 10px;
    /* overflow: auto; */
    /* padding: 0px 5px; */
}
.popper_arrow,
.popper_arrow::before {
  position: absolute;
  width: 10px;
  height: 10px;
  background: inherit;
  border-left: 1px solid #1976d2;
  border-bottom: 1px solid #1976d2;
}

.business_number_actions {
    display: flex;
    align-items: center;
    gap: 10px;
    grid-template-columns: repeat(8, minmax(0, 1fr));
}

.business_search_field{
    display: flex;
    align-items: center;
    gap: 10px;
    grid-column: span 2 / span 2;
}

.business_search_field > span {
  font-size:24px;
  font-weight:bold;
}

.business_action_btn_csv {
    /* grid-column: span 2 / span 2; */

}
.business_search_field {
  width: 200px;
  font-size: 20px;
}
.business_search_field fieldset {
  border-color: #1976d2;
}
.business_number_search_body{
    display: grid;
    gap: 10px;
    margin-top: 20px;
}
.business_number_search_body > div {
  padding-left: 40px;
}
.business_main_field{
    display: grid;
    gap: 22px;
    margin-bottom: 25px;

}
.business_main_field h6 {
  color: #1976d2;
  font-size: 0.81rem;
}
.business_main_field p {
  font-weight: bold;
  font-size: 18px;
}

.business_info_data{
    display: grid;
    gap: 10px;
    padding-left:40px;
    margin-bottom: 70px;
}

.business_info_data_field{
    display:flex;
    flex-wrap: wrap;
    gap: 50px;
}

.business_info_data_field h6{
  width: 15em;
  color: #1976d2;
  font-size: 0.81rem;
}
.business_info_data_field p{
  font-size: 0.81rem;
  font-weight: bold;
}
.layout .business-search-list.table thead tr {
  top: 0;
}
.business_number_search{
    padding: 40px 0px 0px 0;
}
.business_number_search_header {
  padding-left: 40px;
}
.business_number_search_header h6 {
  font-size:20px;
  font-weight: bold;
  margin-bottom: 15px;
}
.business_number_search input[type="file"]#avatar{
  width: 100%;
}
.include_subtitle{
    height: 50px !important;
}

.subtitle_business{

    font-size: 0.85rem !important;
    font-weight: 500 !important;
}
.business_number_search .MuiDialog-container button + button {
  margin-left: 5px;
}
.business-search-list.table {
  width: calc(100% + 40px);
  border-top: 1px solid #e2e2e8;
  margin-top: 45px;
  margin-left: -40px;
}
.business-search-list.table thead .th-action:nth-child(1) {
  text-align: center;
  width: 110px;
}
.business-search-list.table thead .th-action:nth-child(1) div {
  text-align: center;
  margin-left: 0;
}
.business-search-list.table thead .th-action:nth-child(2) {
  width: 150px;
}
.business-search-list.table thead .th-action:nth-child(3) {
  width: 15%;
}
.business-search-list.table thead .th-action:nth-child(4) {
  width: 15%;
}
.business-search-list.table thead .th-action:nth-last-child(-n+3) {
  width: auto;
}
.business-search-list.table thead .th-action:nth-last-child(-n+2) {
  width: 120px;
}
.business-search-list.table thead .th-action:last-child {
  width: 120px;
}
.business-search-list.table td {
  vertical-align: middle;
}
.business_field_exist {
  text-align: center;
}
.business_field_exist span {
  margin:0 auto;
}
.business_field_registrated_number {
  font-weight: bold;
}
.business_field_company_name {
  font-weight: bold;
}
.business_field_name {
  color: #1976d2;
}
.business_field_address {
  color: #1976d2;
}
.business_field_registration_date {
  color: #1976d2;
}
.business_field_update_date {
  color: #1976d2;
}

.popper_arrow {
    left: -6px;
    visibility: hidden;
}

.popper_arrow::before {
    visibility: visible;
    content: '';
    border-left: 1px solid #1976d2;
    border-bottom: 1px solid #1976d2;
    transform: rotate(45deg);
}

.hide_popper{
    display: none;
}

.invoice_number_form_verification{
    display: flex;
    align-items: center;
}

.business_info_data_field_verification{
    display:grid;
}

.business_info_data_wrapper{
    display:grid;
    gap: 5px
}

.business_info_data_wrapper .business_info_data_field_verification h6,
.business_info_data_wrapper .business_info_data_field_verification p,
.business_info_data_wrapper .business_info_data_field_verification activeFlag_active{
  font-size: 0.75rem !important;
}

.business_info_data_field_verification .activeFlag_active{
    width: fit-content;
}

.activeFlag_active .MuiCircularProgress-root{
    width: 15px !important;
    height: 15px !important;
    margin-top: 4px;
    color: white;
}
.activeFlag_inactive .MuiCircularProgress-root{
    width: 15px !important;
    height: 15px !important;
    margin-top: 4px;
    color: white;
}

.business_tooltip_class{
    max-width: 480px;
    font-feature-settings: "palt";
}
.business_tooltip_class .business_tooltip_heading{
  color: #062b60;
}
.popper_suggest .business_tooltip_caution {
  color:#F27200;
  margin-bottom: 15px;
}
.business_number_actions .popper_suggest{
    /* transform: translate(708px, 80px) !important; */
}
.business_number_actions .popper_arrow::before {
  top: 0px;
}

.search_tooltip_class{
    max-width: 480px;
    font-feature-settings: "palt";
}

.search_popper_suggest{
    background: white;
    font-weight: bold;
    padding: 8px 18px;
    font-size: 10px;
    flex-wrap: wrap;
    border-radius: 10px;
    z-index: 9999999;
    filter: drop-shadow(0px 2px 4px rgba(0,0,0,0.6));
    max-height: 150px;
    overflow-y: auto;
    overflow-x: hidden;
}

.additional_info_count {
    display: flex;
    gap: 10px;
    align-items: center;
}

.drive-zip-preview .file-upload-list {
    height: 100%;
    border-top: solid 1px #2c2e3f;
    padding: 15px 10px;
    background: #545d75;
    color: #d5d5e6;
}

.drive-zip-preview .file-upload-list ul {
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.drive-zip-preview .file-upload-list .file-item {
    font-size: 14px;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 34px;
    color: inherit;
    cursor: pointer;
    font-weight: 400;
    padding: 0 0 0 13px;
    border-radius: 2px;
}

.drive-zip-preview .file-upload-list .file-item:not(.active):hover {
    background: #4c536a;
}

.drive-zip-preview .file-upload-list .file-item.active {
    cursor: unset;
    color: #00d0ff;
    font-weight: 500;
}

.modal {
    z-index: 10003;
}

.loading_more_comp {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 10px;
}
.logo-exist-email{
    width: 40px;
    height: 40px;
    padding: 4px;
    background-color: white;
    border: 1px solid #ccc;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 5px;
    transition: all 0.5s;
}
.icon-absolute{
    position: absolute;
    right: 50px;
    top: 28px;
    z-index: 10000;
}
.relative{
    position: relative;
}
