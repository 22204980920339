.delivery_header {
  display: flex;
  gap: 4px;
  align-items: center;
  width: 100%;
}

.delivery_header_action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.text_header{
    width: 110px;
}       

.icon_actions{
    cursor: pointer;
    display:flex;
}

.icon_actions .material_symbol_icon_drive {
  margin-right: 10px;
}
.icon_actions .material_symbol_icon_drive:last-child{
  margin-right: 0;
}